
const IndentEmptySvg = () => {
  return (
    <svg
      width="128"
      height="128"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M63.9494 115.4C93.3824 115.4 117.243 92.477 117.243 64.2C117.243 35.923 93.3824 13 63.9494 13C34.5164 13 10.6562 35.923 10.6562 64.2C10.6562 92.477 34.5164 115.4 63.9494 115.4Z"
        fill="#E5E5EA"
      />
      <path
        d="M70.485 24.5234H23.1821C21.3298 24.5234 19.8281 25.9661 19.8281 27.7457V45.0638C19.8281 46.8434 21.3298 48.286 23.1821 48.286H70.485C72.3374 48.286 73.839 46.8434 73.839 45.0638V27.7457C73.839 25.9661 72.3374 24.5234 70.485 24.5234Z"
        fill="white"
      />
      <path
        d="M67.8141 29.2891H45.0205C43.9737 29.2891 43.125 30.1038 43.125 31.1089C43.125 32.114 43.9737 32.9288 45.0205 32.9288H67.8141C68.861 32.9288 69.7096 32.114 69.7096 31.1089C69.7096 30.1038 68.861 29.2891 67.8141 29.2891Z"
        fill="#E5E5EA"
      />
      <path
        d="M67.8141 34.5859H45.0205C43.9737 34.5859 43.125 35.4007 43.125 36.4058C43.125 37.4109 43.9737 38.2257 45.0205 38.2257H67.8141C68.861 38.2257 69.7096 37.4109 69.7096 36.4058C69.7096 35.4007 68.861 34.5859 67.8141 34.5859Z"
        fill="#E5E5EA"
      />
      <path
        d="M58.3515 39.875H45.0205C43.9737 39.875 43.125 40.6898 43.125 41.6949C43.125 42.6999 43.9737 43.5147 45.0205 43.5147H58.3515C59.3984 43.5147 60.2471 42.6999 60.2471 41.6949C60.2471 40.6898 59.3984 39.875 58.3515 39.875Z"
        fill="#E5E5EA"
      />
      <path
        d="M32.1111 43.6743C36.2931 43.6743 39.6832 40.4173 39.6832 36.3997C39.6832 32.382 36.2931 29.125 32.1111 29.125C27.9292 29.125 24.5391 32.382 24.5391 36.3997C24.5391 40.4173 27.9292 43.6743 32.1111 43.6743Z"
        fill="#D5D5D5"
      />
      <path
        d="M50.6569 51.4375H3.35398C1.50163 51.4375 0 52.8802 0 54.6598V71.9779C0 73.7575 1.50163 75.2001 3.35398 75.2001H50.6569C52.5093 75.2001 54.0109 73.7575 54.0109 71.9779V54.6598C54.0109 52.8802 52.5093 51.4375 50.6569 51.4375Z"
        fill="white"
      />
      <path
        d="M47.9938 56.2031H25.2002C24.1533 56.2031 23.3047 57.0179 23.3047 58.023C23.3047 59.0281 24.1533 59.8429 25.2002 59.8429H47.9938C49.0407 59.8429 49.8893 59.0281 49.8893 58.023C49.8893 57.0179 49.0407 56.2031 47.9938 56.2031Z"
        fill="#E5E5EA"
      />
      <path
        d="M47.9938 61.5H25.2002C24.1533 61.5 23.3047 62.3148 23.3047 63.3199C23.3047 64.3249 24.1533 65.1397 25.2002 65.1397H47.9938C49.0407 65.1397 49.8893 64.3249 49.8893 63.3199C49.8893 62.3148 49.0407 61.5 47.9938 61.5Z"
        fill="#E5E5EA"
      />
      <path
        d="M38.5312 66.7891H25.2002C24.1533 66.7891 23.3047 67.6038 23.3047 68.6089C23.3047 69.614 24.1533 70.4288 25.2002 70.4288H38.5312C39.5781 70.4288 40.4267 69.614 40.4267 68.6089C40.4267 67.6038 39.5781 66.7891 38.5312 66.7891Z"
        fill="#E5E5EA"
      />
      <path
        d="M12.283 70.5884C16.4649 70.5884 19.8551 67.3314 19.8551 63.3137C19.8551 59.296 16.4649 56.0391 12.283 56.0391C8.10107 56.0391 4.71094 59.296 4.71094 63.3137C4.71094 67.3314 8.10107 70.5884 12.283 70.5884Z"
        fill="#D5D5D5"
      />
      <path
        d="M70.485 78.3516H23.1821C21.3298 78.3516 19.8281 79.7942 19.8281 81.5738V98.8919C19.8281 100.672 21.3298 102.114 23.1821 102.114H70.485C72.3374 102.114 73.839 100.672 73.839 98.8919V81.5738C73.839 79.7942 72.3374 78.3516 70.485 78.3516Z"
        fill="white"
      />
      <path
        d="M67.8141 83.1172H45.0205C43.9737 83.1172 43.125 83.932 43.125 84.937C43.125 85.9421 43.9737 86.7569 45.0205 86.7569H67.8141C68.861 86.7569 69.7096 85.9421 69.7096 84.937C69.7096 83.932 68.861 83.1172 67.8141 83.1172Z"
        fill="#E5E5EA"
      />
      <path
        d="M67.8141 88.4141H45.0205C43.9737 88.4141 43.125 89.2288 43.125 90.2339C43.125 91.239 43.9737 92.0538 45.0205 92.0538H67.8141C68.861 92.0538 69.7096 91.239 69.7096 90.2339C69.7096 89.2288 68.861 88.4141 67.8141 88.4141Z"
        fill="#E5E5EA"
      />
      <path
        d="M58.3515 93.7031H45.0205C43.9737 93.7031 43.125 94.5179 43.125 95.523C43.125 96.5281 43.9737 97.3428 45.0205 97.3428H58.3515C59.3984 97.3428 60.2471 96.5281 60.2471 95.523C60.2471 94.5179 59.3984 93.7031 58.3515 93.7031Z"
        fill="#E5E5EA"
      />
      <path
        d="M32.1111 97.5024C36.2931 97.5024 39.6832 94.2455 39.6832 90.2278C39.6832 86.2101 36.2931 82.9531 32.1111 82.9531C27.9292 82.9531 24.5391 86.2101 24.5391 90.2278C24.5391 94.2455 27.9292 97.5024 32.1111 97.5024Z"
        fill="#D5D5D5"
      />
      <path
        d="M103.325 55.5901C103.365 55.5667 103.399 55.5332 103.422 55.493C103.445 55.4527 103.457 55.4071 103.457 55.3607C103.457 55.3143 103.445 55.2686 103.422 55.2284C103.399 55.1881 103.365 55.1546 103.325 55.1312L93.1825 49.2383C92.5335 48.8622 91.7967 48.6641 91.0465 48.6641C90.2964 48.6641 89.5596 48.8622 88.9106 49.2383L78.7697 55.1312C78.7296 55.1546 78.6963 55.1881 78.6732 55.2284C78.6501 55.2686 78.6379 55.3143 78.6379 55.3607C78.6379 55.4071 78.6501 55.4527 78.6732 55.493C78.6963 55.5332 78.7296 55.5667 78.7697 55.5901L90.9141 62.7341C90.9549 62.7582 91.0015 62.7708 91.0489 62.7708C91.0963 62.7708 91.1428 62.7582 91.1837 62.7341L103.325 55.5901ZM77.6328 57.397C77.5923 57.3736 77.5463 57.3614 77.4996 57.3614C77.4528 57.3615 77.4069 57.3739 77.3664 57.3974C77.326 57.421 77.2925 57.4547 77.2693 57.4954C77.2461 57.536 77.2341 57.582 77.2344 57.6288V69.1762C77.2354 69.7326 77.382 70.2791 77.6598 70.7613C77.9375 71.2435 78.3367 71.6446 78.8175 71.9247L89.5859 78.3814C89.6263 78.4047 89.6721 78.417 89.7187 78.417C89.7653 78.417 89.8111 78.4047 89.8515 78.3815C89.8918 78.3582 89.9254 78.3247 89.9487 78.2843C89.972 78.244 89.9843 78.1982 89.9844 78.1516V64.7542C89.9843 64.7076 89.972 64.6618 89.9487 64.6215C89.9254 64.5812 89.8919 64.5477 89.8516 64.5244L77.6328 57.397ZM92.1094 64.8007V78.1483C92.1094 78.1949 92.1217 78.2407 92.1451 78.281C92.1684 78.3214 92.2019 78.3549 92.2423 78.3781C92.2827 78.4014 92.3285 78.4137 92.3751 78.4137C92.4217 78.4137 92.4675 78.4014 92.5078 78.3781L103.276 71.9214C103.756 71.6417 104.155 71.2411 104.433 70.7596C104.711 70.278 104.858 69.7321 104.859 69.1762V57.6288C104.859 57.5822 104.847 57.5365 104.823 57.4963C104.8 57.456 104.767 57.4226 104.726 57.3994C104.686 57.3762 104.64 57.364 104.594 57.3641C104.547 57.3641 104.501 57.3764 104.461 57.3997L92.2422 64.5716C92.202 64.5948 92.1685 64.6282 92.1452 64.6684C92.1219 64.7086 92.1095 64.7542 92.1094 64.8007Z"
        fill="#A3A3A3"
      />
    </svg>
  )
}

export default IndentEmptySvg
