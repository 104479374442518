import React from 'react'
import { useNavigate } from '@remix-run/react'
import { CirclePlus, Trash, Plus } from 'lucide-react'
import { Button } from '~/components/ui/button'
import IndentEmptySvg from '~/components/assets/svg/indentEmptySvg'
import { Icity } from '../type'
import { constants } from '~/utils/constants'
import Dot from '~/components/ui/custom_ui/DotIndicator'

export function Errors(props: { error?: string, note?: string }) {
  if (!props.error) return null
  return <p className="!mt-1 text-destructive">{props?.note?props?.note+' - ':""}{props.error}</p>
}

interface IcityItems {
  cities: any[]
  error?: string
  remove: any
  navigateTo?: ()=>void
  type?: 'S'
  placeholder: string
  sourceLength?: number
}

export const CityItems = (props: IcityItems) => {
  const { cities, error, remove, navigateTo, type, placeholder, sourceLength } = props
  return (
    <>
      {cities?.length > 0 ? (
        cities.map((city: Icity, index: number) => {
          return (
            <CityItem
              key={`${city.city_id}${index}`}
              type={type}
              index={sourceLength ? sourceLength + index : index}
              error={error}>
              <ItemDetail
                city={city}
                navigateTo={navigateTo}
                remove={remove}
                index={index}
                type={type}
              />
            </CityItem>
          )
        })
      ) : (
        <CityItem
          type={type}
          index={!type ? sourceLength || 1 : 0}
          navigateTo={navigateTo}
          error={error}>
          <p className="py-2 text-subline">{placeholder}</p>
        </CityItem>
      )}
    </>
  )
}
interface IcityItem {
  children: React.ReactNode
  type?: 'S' | 'D'
  index: number
  error?: string
  navigateTo?: () => void
}
const CityItem = (props: IcityItem) => {
  const { children, type, index, error, navigateTo } = props

  return (
    <div>
      <div className="relative z-10 my-2 flex items-center justify-center gap-2">
        <Dot varient={type === 'S' ? 'loading' : 'unloading'} text={'present'}>
          {index + 1}
        </Dot>
        <div
          onClick={navigateTo || undefined}
          className={`w-full rounded-md border p-1 pl-3 ${!!navigateTo ? 'cursor-pointer' : ''}`}>
          {children}
        </div>
      </div>
      {error ? (
        <div className="ml-6">
          <Errors error={error} />
        </div>
      ) : null}
    </div>
  )
}
interface IitemDetail {
  city: Icity
  index: number
  remove: Function
  navigateTo?: ()=>void
  type?: 'S' | 'D'
}
const ItemDetail = (props: IitemDetail) => {
  const { city, index, remove, navigateTo } = props
  
  const handleRemove = () => {
    remove(index)
  }

  return (
    <div className="flex items-center justify-between">
      <div className="flex-1">
        <h2 className="truncate whitespace-nowrap">
          {city.city_name}
          <span >, {city.country_name}</span>
        </h2>
      </div>
      <div className="flex flex-row-reverse">
        <Button
          type="button"
          variant={'ghost'}
          size={'icon'}
          onClick={navigateTo || undefined}>
          <CirclePlus size={16} className="text-primary" />
        </Button>
        <Button type="button" variant={'ghost'} size={'icon'} onClick={handleRemove}>
          <Trash size={16} className="text-red-600" />
        </Button>
      </div>
    </div>
  )
}

export const IndentEmpty = () => {
  const navigate = useNavigate()
  // Todo url from constants
  const createIndent = () => navigate(constants.shipper.createIndent,{replace: true})
  return (
    <div className="flex min-h-[60vh] flex-col items-center justify-center space-y-5">
      <IndentEmptySvg />
      <p className="text-xl text-subline/70">Create load & get best matching trucks</p>
      <Button
        className="bg-card text-secondary shadow-md"
        variant={'ghost'}
        onClick={createIndent}>
        <Plus className="mr-2" size={18} /> Add new load
      </Button>
    </div>
  )
}
